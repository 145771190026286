import * as React from "react"

import "typeface-roboto"
import "typeface-oswald"
import "../styles/document.scss"

import styles from "./404.module.scss"

const NotFound = () => (
  <div className={styles.main}>
    <h1>404</h1>
    <p>Page Not Found</p>
  </div>
)

export default NotFound
